<template>
  <div class="head-container" style="float:right;">
    <!-- 搜索 -->
     <div class="filter-item">
      时间
      <el-date-picker
        style="width: 150px; z-index:99;"
        v-model="query.startTime"
        type="date"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
        v-if="checkPermission(['ADMIN'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="resetStatisticss">初始化数据</el-button>
  </div>
</template>

<script>
import { initData } from '@/api/data'
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseDate } from '@/utils/index'
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  created() {
  },
  methods: {
    checkPermission,
    parseDate,
    toQuery() {
      this.$parent.init()
    },
    resetStatisticss(){
      if(this.query.startTime==null ||this.query.startTime=="" ){
        this.$notify({
          title: '请在开始时间选择需要初始化数据的日期！',
          type: 'error',
          duration: 2500
        })
      }
      let time = this.parseDate(this.query.startTime)
      initData('/crm/resetStatisticss', {startTime:time}).then(res => {
        this.$notify({
          title: '初始化成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
      })
    }
  }
}
</script>
