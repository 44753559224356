<template>
  <div class="app-container" style="width:100%">
    <div class="blod">账户资金统计</div>
    <eHeader :query="query" />
    <!--表格渲染-->
    <div class="chart-outer">
      <baseLineChart ref="chart"
        :chart-config="chartConfig"
      ></baseLineChart>
    </div>
  </div>
</template>

<script>
import { initData } from '@/api/data'
import { parseTime,parseDate } from '@/utils/index'
import baseLineChart from '@/components/echarts/baseChart'
import eHeader from '@/components/stat/report/accEquityTotal/header'
export default {
  components: { eHeader,baseLineChart },
  props:{
  },
  data() {
    return {
      sup_this: this,
      data: [],
      url: '',
      params: {},
      query: {},
      chartConfig: {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            bottom: '0',
            left: 'center',
            // data:['净值','余额'],
            data:['账户总净值','账户总余额'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          /*toolbox: {
            feature: {
              saveAsImage: {}
            }
          },*/
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name:'账户总净值',
              type:'line',
              data:[],
              temStyle: {
                normal: {
                  color: '#3888fa',
                  lineStyle: {
                    color: '#3888fa',
                    width: 2
                  },
                  /*区域颜色*/
                  /*areaStyle: {
                  /  color: '#f3f8ff'
                  }*/
                }
              },
            },
            {
              name:'账户总余额',
              type:'line',
              data:[],
              temStyle: {
                normal: {
                  color: '#3888fa',
                  lineStyle: {
                    color: '#3888fa',
                    width: 2
                  },
                  /*区域颜色*/
                  /*areaStyle: {
                    color: '#f3f8ff'
                  }*/
                }
              },
            },
          ]
        }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseDate,
    parseTime,
    beforeInit() {
      this.url = '/crm/queryStatistics'
      this.params = { queryType:2}
      const query = this.query
      const startTime = this.parseTime(query.startTime)
      const endTime = this.parseTime(query.endTime)
      if (startTime!=null && startTime!='') { this.params['startTime'] = startTime }
      if (endTime!=null && endTime!='') { this.params['endTime'] = endTime }
      return true
    },
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        initData(this.url, this.params).then(res => {
          this.data = res.content
          let xAxisData=[]
          let seriesData0=[]
          let seriesData1=[]
          this.data.forEach(element => {
            xAxisData.push(this.parseDate(element.dateTime))
            seriesData0.push(element.worth)
            seriesData1.push(element.balance)
          });
          this.chartConfig.xAxis.data = xAxisData;
          this.chartConfig.series[1].data = seriesData1;
          this.chartConfig.series[0].data = seriesData0;
          this.$refs.chart.set();
        }).catch(err => {
        })
      })
    },
  }
}
</script>

<style scoped>
  .chart-outer {
      width: 835px;
      height: 306px;
    }
  .blod {
    font-weight: 600;
    font-size: 22px;
  }
</style>
